import {
  ADMIN_GET_STOCKS,
  ADMIN_ADD_STOCK,
  ADMIN_UPDATE_STOCK,
  ADMIN_DELETE_STOCK,
} from '../constants/stockConstants'

export default function stockReducer(state = { stocks: [] }, action) {
  switch (action.type) {
    case ADMIN_GET_STOCKS: {
      return {
        stocks: action.payload,
      }
    }
    case ADMIN_ADD_STOCK: {
      let stocks = [...state.stocks]
      stocks.unshift(action.payload)
      return {
        ...state,
        stocks: stocks,
      }
    }
    case ADMIN_UPDATE_STOCK: {
      let stocks = [...state.stocks]
      let index = stocks.findIndex((stock) => stock._id === action.payload._id)
      stocks[index] = action.payload
      return {
        ...state,
        stocks: stocks,
      }
    }

    case ADMIN_DELETE_STOCK: {
      let stocks = [...state.stocks]
      let index = stocks.findIndex((stock) => stock._id === action.payload)
      stocks.splice(index, 1)
      return {
        ...state,
        stocks: stocks,
      }
    }
    default: {
      return state
    }
  }
}
