import {
  ADMIN_GET_VENDORS,
  ADMIN_ADD_VENDOR,
  ADMIN_UPDATE_VENDOR,
  ADMIN_DELETE_VENDOR,
} from '../constants/vendorConstants'

export default function vendorReducer(state = { vendors: [] }, action) {
  switch (action.type) {
    case ADMIN_GET_VENDORS: {
      return {
        vendors: action.payload,
      }
    }
    case ADMIN_ADD_VENDOR: {
      let vendors = [...state.vendors]
      vendors.unshift(action.payload)
      return {
        ...state,
        vendors: vendors,
      }
    }
    case ADMIN_UPDATE_VENDOR: {
      let vendors = [...state.vendors]
      let index = vendors.findIndex((vendor) => vendor._id === action.payload._id)
      vendors[index] = action.payload
      return {
        ...state,
        vendors: vendors,
      }
    }

    case ADMIN_DELETE_VENDOR: {
      let vendors = [...state.vendors]
      let index = vendors.findIndex((vendor) => vendor._id === action.payload)
      vendors.splice(index, 1)
      return {
        ...state,
        vendors: vendors,
      }
    }
    default: {
      return state
    }
  }
}
