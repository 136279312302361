import {
  ADMIN_GET_SUPPLIER,
  ADMIN_ADD_SUPPLIER,
  ADMIN_UPDATE_SUPPLIER,
  ADMIN_DELETE_SUPPLIER,
} from '../constants/supplierConstant'

export default function supplierReducer(state = { suppliers: [] }, action) {
  switch (action.type) {
    case ADMIN_GET_SUPPLIER: {
      return {
        suppliers: action.payload,
      }
    }

    case ADMIN_ADD_SUPPLIER: {
      let suppliers = [...state.suppliers]
      suppliers.unshift(action.payload)
      return {
        ...state,
        suppliers: suppliers,
      }
    }

    case ADMIN_UPDATE_SUPPLIER: {
      let suppliers = [...state.suppliers]
      let index = suppliers.findIndex((supplier) => supplier._id === action.payload._id)
      suppliers[index] = action.payload
      return {
        ...state,
        suppliers: suppliers,
      }
    }

    case ADMIN_DELETE_SUPPLIER: {
      let suppliers = [...state.suppliers]
      let index = suppliers.findIndex((supplier) => supplier._id === action.payload)
      suppliers.splice(index, 1)
      return {
        ...state,
        suppliers: suppliers,
      }
    }

    default: {
      return state
    }
  }
}
