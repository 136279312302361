import {
  ADMIN_GET_CUSTOMER,
  ADMIN_ADD_CUSTOMER,
  ADMIN_UPDATE_CUSTOMER,
  ADMIN_DELETE_CUSTOMER,
} from '../constants/customerConstant'

export default function customerReducer(state = { customers: [] }, action) {
  switch (action.type) {
    case ADMIN_GET_CUSTOMER: {
      return {
        customers: action.payload,
      }
    }

    case ADMIN_ADD_CUSTOMER: {
      let customers = [...state.customers]
      customers.unshift(action.payload)
      return {
        ...state,
        customers: customers,
      }
    }

    case ADMIN_UPDATE_CUSTOMER: {
      let customers = [...state.customers]
      let index = customers.findIndex((customer) => customer._id === action.payload._id)
      customers[index] = action.payload
      return {
        ...state,
        customers: customers,
      }
    }

    case ADMIN_DELETE_CUSTOMER: {
      let customers = [...state.customers]
      let index = customers.findIndex((customer) => customer._id === action.payload)
      customers.splice(index, 1)
      return {
        ...state,
        customers: customers,
      }
    }

    default: {
      return state
    }
  }
}
